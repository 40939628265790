import type { SVGProps } from 'react'

const SVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    className="prefix__icon"
    viewBox="0 0 1024 1024"
    xmlns="http://www.w3.org/2000/svg"
    width={200}
    height={200}
    {...props}
  >
    <defs>
      <style />
    </defs>
    <path d="M727.77 116.032a81.056 81.056 0 00-9.108-14.01c-11.212-13.312-25.216-25.216-41.33-35.73-16.82-9.806-30.823-16.814-42.74-19.61-5.6-.704-12.608-1.402-19.61-2.106-7.008 0-14.713.698-21.721 2.803-7.706 1.402-14.714 4.205-21.722 8.403-7.008 4.903-13.305 10.51-18.214 17.51-4.909 6.305-9.805 14.01-16.115 24.52-6.304 9.81-11.207 18.214-16.116 25.222l177.94 102.976c2.803-4.205 5.606-8.404 9.107-13.312 2.105-4.903 5.606-9.805 9.113-15.412 3.501-5.6 7.002-12.608 11.904-20.313 3.508-7.706 6.304-14.714 7.008-21.715.698-7.706 0-14.01-1.401-21.018-2.093-6.298-4.199-12.602-6.995-18.208zM376.096 373.139l-44.134 77.056-40.634 70.055c-11.91 21.017-21.722 38.534-30.125 52.544-7.705 14.003-13.312 23.11-15.411 27.321-4.902 8.403-8.403 16.115-10.509 23.117-2.803 7.008-4.205 14.01-5.606 21.722-.698 5.6-2.1 14.707-2.803 27.321-.704 13.312-1.402 28.02-2.106 43.43 0 15.412-.698 30.125-.698 44.135s0 25.216.698 32.224c2.106 14.01 5.6 22.42 11.206 27.322 6.304 4.204 16.116 3.507 28.724-1.402 6.304-2.803 15.41-7.706 28.019-14.714s25.222-15.41 38.528-23.82a1211.437 1211.437 0 0038.534-25.92c12.615-9.108 21.722-15.412 28.026-21.012 5.6-4.908 11.904-11.212 16.813-17.516 4.902-7.008 9.804-13.306 13.305-18.912 1.408-2.1 6.304-9.805 14.01-23.117s17.51-30.125 28.723-51.136c11.904-20.32 25.216-42.74 39.93-67.955l44.134-77.056 118.394-204.564-178.637-103.68L376.096 373.14zm494.586 522.605c0 24.518-20.32 44.838-44.839 44.838h-628.39c-24.519 0-44.135-20.32-44.135-44.838s19.616-44.838 44.135-44.838h628.39c24.519 0 44.839 20.32 44.839 44.838zm0-145.011c0 24.518-20.32 44.832-44.839 44.832H549.83c-24.518 0-44.838-20.314-44.838-44.832a44.704 44.704 0 0144.838-44.839h276.013a44.704 44.704 0 0144.839 44.839z" />
  </svg>
)

export default SVG
