import type { SVGProps } from 'react'

const SVG = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      className="icon"
      viewBox="0 0 1024 1024"
      xmlns="http://www.w3.org/2000/svg"
      width={200}
      height={200}
      {...props}
    >
      <path d="M721.067 337.067c-25.6 0-42.667 8.533-59.734 25.6-12.8 12.8-21.333 29.866-21.333 59.733 0 25.6 8.533 46.933 21.333 64 12.8 17.067 29.867 25.6 59.734 25.6 17.066 0 29.866-4.267 42.666-12.8-12.8-17.067-25.6-25.6-34.133-38.4l21.333-21.333c12.8 12.8 25.6 25.6 34.134 38.4 8.533-17.067 12.8-29.867 12.8-51.2 0-25.6-4.267-46.934-21.334-68.267-21.333-12.8-34.133-21.333-55.466-21.333zm-234.667 12.8-42.667 102.4H524.8z" />
      <path d="M861.867 42.667H162.133c-68.266 0-119.466 51.2-119.466 119.466V691.2c0 68.267 51.2 119.467 119.466 119.467h59.734v170.666L456.533 806.4h409.6c68.267 0 119.467-51.2 119.467-119.467v-524.8c-4.267-68.266-55.467-119.466-123.733-119.466zM366.933 341.333H247.467V409.6h115.2v29.867h-115.2v102.4h-38.4v-230.4h157.866v29.866zm187.734 200.534-25.6-59.734h-98.134l-25.6 59.734H371.2l89.6-230.4h38.4l89.6 230.4h-34.133zm273.066 4.266-25.6 21.334c-8.533-12.8-17.066-25.6-25.6-34.134-17.066 12.8-38.4 17.067-64 17.067-34.133 0-64-12.8-81.066-34.133-21.334-25.6-25.6-51.2-25.6-85.334s8.533-64 25.6-85.333c21.333-25.6 46.933-34.133 81.066-34.133 34.134 0 64 12.8 81.067 34.133 21.333 25.6 25.6 51.2 25.6 85.333s-8.533 64-25.6 85.334c17.067 8.533 25.6 21.333 34.133 29.866z" />
    </svg>
  )
}

export default SVG
